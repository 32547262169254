import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './screens/Dashboard';
import Journal from './screens/Journal';
import Login from './screens/Login';
import Register from './screens/Register';
import Profile from './screens/Profile';
import TradeEditScreen from './screens/TradeEditScreen';

const App = () => {
  return (
    <>
      <main>
        <Routes>
          <Route path='/' element={<Header />}>
            <Route index element={<Dashboard />} />
            <Route path='/journal' element={<Journal />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/trades/:id/' element={<TradeEditScreen />} />
          </Route>
        </Routes>
      </main>
      <Footer />
    </>
  );
};

export default App;

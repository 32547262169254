import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const TradesByWeekdayBarChart = ({ labels, data, title }) => {
  const options = {
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const tradesByWeekdayChartData = {
    labels: labels,
    datasets: [
      {
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
        hoverBorderColor: 'rgba(54, 162, 235, 1)',
        data: data,
      },
    ],
  };

  return (
    <>
      <p className='chart-title'>{title}</p>
      <Bar data={tradesByWeekdayChartData} options={options} />
    </>
  );
};

export default TradesByWeekdayBarChart;

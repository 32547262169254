import axios from 'axios';
import {
  TRADE_LIST_SUCCESS,
  TRADE_LIST_REQUEST,
  TRADE_LIST_FAILURE,
  TRADE_CREATE_SUCCESS,
  TRADE_CREATE_REQUEST,
  TRADE_CREATE_FAILURE,
  TRADE_UPDATE_SUCCESS,
  TRADE_UPDATE_REQUEST,
  TRADE_UPDATE_FAILURE,
  TRADE_DELETE_SUCCESS,
  TRADE_DELETE_REQUEST,
  TRADE_DELETE_FAILURE,
  TRADE_DETAILS_REQUEST,
  TRADE_DETAILS_SUCCESS,
  TRADE_DETAILS_FAILURE,
} from '../constants/tradeConstants';

export const listTrades = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TRADE_LIST_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };
    const { data } = await axios.get('/api/trades', config);
    dispatch({ type: TRADE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: TRADE_LIST_FAILURE, payload: error.message });
  }
};

export const createTrade = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TRADE_CREATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };
    const { data } = await axios.post('/api/trades', {}, config);
    dispatch({ type: TRADE_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: TRADE_CREATE_FAILURE, payload: error.message });
  }
};

export const listTradeDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRADE_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/trades/${id}`);
    dispatch({ type: TRADE_DETAILS_SUCCESS, payload: data });
    // console.log(data);
  } catch (error) {
    dispatch({ type: TRADE_DETAILS_FAILURE, payload: error.message });
  }
};

export const deleteTrade = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRADE_DELETE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };
    await axios.delete(`/api/trades/${id}`, config);
    dispatch({ type: TRADE_DELETE_SUCCESS });
  } catch (error) {
    dispatch({ type: TRADE_DELETE_FAILURE, payload: error.message });
  }
};

export const updateTrade = (trade) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRADE_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(`/api/trades/${trade._id}`, trade, config);
    dispatch({ type: TRADE_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: TRADE_UPDATE_FAILURE, payload: error.message });
  }
};

import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {
  createStore,
  combineReducers,
  applyMiddleware,
} from '@reduxjs/toolkit';
import {
  tradeListReducer,
  tradeCreateReducer,
  tradeDeleteReducer,
  tradeDetailsReducer,
  tradeUpdateReducer,
} from './reducers/tradeReducers';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
} from './reducers/userReducers';

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const reducer = combineReducers({
  tradeList: tradeListReducer,
  tradeUpdate: tradeUpdateReducer,
  tradeDetails: tradeDetailsReducer,
  tradeCreate: tradeCreateReducer,
  tradeDelete: tradeDeleteReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
});

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

// const store = configureStore({
//   reducer: {
//     tradeList: tradeListReducer,
//     userLogin: userLoginReducer,
//     userRegister: userRegisterReducer,
//     userDetails: userDetailsReducer,
//     userUpdateProfile: userUpdateProfileReducer,
//   },
//   initialState,
// });

export default store;

import React from 'react';
import { Container, Table } from 'react-bootstrap';

const StatisticsTableMain = ({ tradeData }) => {
  return (
    <Container className='my-4'>
      <Table className='my-5' striped hover>
        <tbody>
          <tr>
            <td>Total gain/loss:</td>
            <td>
              {tradeData.totalPnl >= 0
                ? '$' + parseFloat(tradeData.totalPnl).toLocaleString()
                : '-$' + (tradeData.totalPnl * -1).toLocaleString()}
            </td>
            <td>Average daily gain/loss:</td>
            <td>
              {tradeData.averageGainPerDay >= 0
                ? '$' + parseFloat(tradeData.averageGainPerDay).toLocaleString()
                : '-$' + (tradeData.averageGainPerDay * -1).toLocaleString()}
            </td>
          </tr>
          <tr>
            <td>Trading Days Invested:</td>
            <td>{tradeData.numberTrades}</td>
            <td>Profit Factor:</td>
            <td>{tradeData.profitFactor}</td>
          </tr>
          <tr>
            <td>Number of positive Days:</td>
            <td>{tradeData.numberOfWins}</td>
            <td>Number of negative Days:</td>
            <td>{tradeData.numberOfLosses}</td>
          </tr>
          <tr>
            <td>Positive Days:</td>
            <td>{tradeData.winRate}%</td>
            <td>Return:</td>
            <td
              style={{
                color: tradeData.percentageReturn > 0 ? 'green' : 'red',
              }}
            >
              {tradeData.percentageReturn}%
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default StatisticsTableMain;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { listTrades, deleteTrade, createTrade } from '../actions/tradeActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { TRADE_CREATE_RESET } from '../constants/tradeConstants';
import AccountHistoryTable from '../components/AccountHistoryTable';

const Journal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tradeCreate = useSelector((state) => state.tradeCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    trade: createdTrade,
  } = tradeCreate;

  const tradeList = useSelector((state) => state.tradeList);
  const { loading, error, trades } = tradeList;

  const tradeDelete = useSelector((state) => state.tradeDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = tradeDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this trade?')) {
      dispatch(deleteTrade(id));
    }
  };

  const createTradeHandler = () => {
    dispatch(createTrade());
  };

  useEffect(() => {
    dispatch({ type: TRADE_CREATE_RESET });
    if (!userInfo) {
      navigate('/login');
    }

    if (successCreate) {
      navigate(`/trades/${createdTrade._id}`);
    } else {
      dispatch(listTrades());
    }
  }, [
    dispatch,
    navigate,
    userInfo,
    successDelete,
    successCreate,
    createdTrade,
  ]);

  //dictionary of trades with comment
  var investments = [];
  trades.forEach((trade) => {
    if (trade.comment === 'Investment') {
      investments.push(trade);
    } else if (trade.comment === 'Withdrawal') {
      investments.push(trade);
    }
  });

  return (
    <div className='my-4 mx-5'>
      <Row className='my-3 justify-content-center'>
        <Col className='justify-content-right' md={{ span: 4, offset: 4 }}>
          <h1 className='table-header'>Transaction History</h1>
        </Col>
        <Col md={{ span: 2, offset: 2 }}></Col>
      </Row>
      {loadingCreate && <Loader />}
      {errorCreate && <Message type='danger' message={errorCreate} />}
      {/* {loadingDelete && <Loader />} */}
      {errorDelete && <Message type='error' message={errorDelete} />}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message />
      ) : (
        <AccountHistoryTable tradeData={investments} />
      )}
    </div>
  );
};

export default Journal;

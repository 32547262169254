import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

let currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer>
      <Container className='my-5'>
        <Row>
          <Col className='text-center py-3'>
            <p>&copy; {currentYear} Palantos Technologies LLC</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { ReactComponent as PalantosLogo } from '../logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions';
import { createTrade } from '../actions/tradeActions';

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  const createTradeHandler = () => {
    dispatch({ createTrade });
  };

  return (
    <header>
      <Navbar collapseOnSelect expand='lg' bg='light' variant='light'>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <PalantosLogo className='logo' />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto ms-auto'>
              <LinkContainer to='/'>
                <Nav.Link>Dashboard</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/journal'>
                <Nav.Link>Transactions</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav className=''>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id='user-dropdown'>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </header>
  );
};

export default Header;

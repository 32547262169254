import React from 'react';
import { Container, Table } from 'react-bootstrap';

const AccountDataTable = ({ accountData }) => {
  return (
    <Container className='my-4'>
      <Table className='my-5 account-data-table' striped hover>
        <tbody>
          {/* <tr>
            <td>Investor ID: </td>
            <td>{accountData.investorID}</td>
          </tr> */}
          <tr>
            <td>Amount Invested: </td>
            <td>${accountData.amountInvested.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Amount Withdrawn: </td>
            <td>${(accountData.amountWithdrawn * -1).toLocaleString()}</td>
          </tr>
          <tr>
            <td>Account Balance: </td>
            <td>${parseFloat(accountData.accountBalance).toLocaleString()}</td>
          </tr>
          <tr>
            <td>Total Return: </td>
            <td>
              {parseFloat(accountData.totalReturnPercentage).toLocaleString()}%
            </td>
          </tr>
          <tr>
            <td>Balance after Split/Fees: </td>
            <td>{parseFloat(accountData.netBalance).toLocaleString()}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default AccountDataTable;

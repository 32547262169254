export const TRADE_LIST_REQUEST = 'TRADE_LIST_REQUEST';
export const TRADE_LIST_SUCCESS = 'TRADE_LIST_SUCCESS';
export const TRADE_LIST_FAILURE = 'TRADE_LIST_FAILURE';
export const TRADE_LIST_RESET = 'TRADE_LIST_RESET';

export const TRADE_CREATE_REQUEST = 'TRADE_CREATE_REQUEST';
export const TRADE_CREATE_SUCCESS = 'TRADE_CREATE_SUCCESS';
export const TRADE_CREATE_FAILURE = 'TRADE_CREATE_FAILURE';
export const TRADE_CREATE_RESET = 'TRADE_CREATE_RESET';

export const TRADE_DELETE_REQUEST = 'TRADE_DELETE_REQUEST';
export const TRADE_DELETE_SUCCESS = 'TRADE_DELETE_SUCCESS';
export const TRADE_DELETE_FAILURE = 'TRADE_DELETE_FAILURE';

export const TRADE_DETAILS_REQUEST = 'TRADE_DETAILS_REQUEST';
export const TRADE_DETAILS_SUCCESS = 'TRADE_DETAILS_SUCCESS';
export const TRADE_DETAILS_FAILURE = 'TRADE_DETAILS_FAILURE';

export const TRADE_UPDATE_REQUEST = 'TRADE_UPDATE_REQUEST';
export const TRADE_UPDATE_SUCCESS = 'TRADE_UPDATE_SUCCESS';
export const TRADE_UPDATE_FAILURE = 'TRADE_UPDATE_FAILURE';
export const TRADE_UPDATE_RESET = 'TRADE_UPDATE_RESET';

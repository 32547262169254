import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const ProfitBySymbolBarChart = ({ labels, data, title }) => {
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const ProfitBySymbolChartData = {
    labels: labels,
    datasets: [
      {
        backgroundColor: function (context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < 0 ? 'rgba(255,99,132,0.2)' : 'rgba(54, 162, 235, 0.2)';
        },
        borderColor: function (context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < 0 ? 'rgba(255,99,132,1)' : 'rgba(54, 162, 235, 1)';
        },
        borderWidth: 1,
        hoverBackgroundColor: function (context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < 0 ? 'rgba(255,99,132,0.4)' : 'rgba(54, 162, 235, 0.4)';
        },
        hoverBorderColor: function (context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < 0 ? 'rgba(255,99,132,1)' : 'rgba(54, 162, 235, 1)';
        },
        data: data,
      },
    ],
  };

  return (
    <>
      <p className='chart-title'>{title}</p>
      <Bar data={ProfitBySymbolChartData} options={options} />
    </>
  );
};

export default ProfitBySymbolBarChart;

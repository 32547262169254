import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Form,
  Button,
  Col,
  Row,
  Card,
  FormGroup,
  Container,
} from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { listTradeDetails, updateTrade } from '../actions/tradeActions';
import FormContainer from '../components/FormContainer';
import { TRADE_UPDATE_RESET } from '../constants/tradeConstants';
import CardHeader from 'react-bootstrap/esm/CardHeader';

const TradeEditScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tradeId = useParams().id;
  const [dateEntry, setDateEntry] = useState('');
  const [positionID, setPositionID] = useState('');
  const [timeEntry, setTimeEntry] = useState('');
  const [dateExit, setDateExit] = useState('');
  const [timeExit, setTimeExit] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [priceEntry, setPriceEntry] = useState(0);
  const [priceExit, setPriceExit] = useState(0);
  const [symbol, setSymbol] = useState('');
  const [direction, setDirection] = useState('');
  const [priceStopLoss, setPriceStopLoss] = useState(0);
  const [priceTakeProfit, setPriceTakeProfit] = useState(0);
  const [dollarProfit, setDollarProfit] = useState(0);
  const [comment, setComment] = useState('');
  const [link, setLink] = useState('');
  const [link1, setLink1] = useState('');
  const [link2, setLink2] = useState('');
  const [tags, setTags] = useState('');
  const [commissions, setCommissions] = useState(0);
  const [swap, setSwap] = useState(0);
  const [accountName, setAccountName] = useState('');

  const tradeDetails = useSelector((state) => state.tradeDetails);
  const { loading, error, trade } = tradeDetails;

  const tradeUpdate = useSelector((state) => state.tradeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = tradeUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: TRADE_UPDATE_RESET });
      navigate('/journal');
      window.location.reload(false);
    } else {
      if (!trade.dateEntry || trade._id !== tradeId) {
        dispatch(listTradeDetails(tradeId));
      } else {
        setPositionID(trade.positionID);
        setDateEntry(trade.dateEntry);
        setTimeEntry(trade.timeEntry);
        setDateExit(trade.dateExit);
        setTimeExit(trade.timeExit);
        setQuantity(trade.quantity);
        setPriceEntry(trade.priceEntry);
        setPriceExit(trade.priceExit);
        setSymbol(trade.symbol);
        setDirection(trade.direction);
        setPriceStopLoss(trade.priceStopLoss);
        setPriceTakeProfit(trade.priceTakeProfit);
        setDollarProfit(trade.dollarProfit);
        setComment(trade.comment);
        setAccountName(trade.accountName);
        setTags(trade.tags);
        setLink(trade.link);
        setLink1(trade.link1);
        setLink2(trade.link2);
        setCommissions(trade.commissions);
        setSwap(trade.swap);
      }
    }
  }, [dispatch, navigate, tradeId, trade, successUpdate]);

  const submmitHandler = (e) => {
    e.preventDefault();
    if (
      quantity === 0 ||
      priceEntry === 0 ||
      priceExit === 0 ||
      priceStopLoss === 0 ||
      priceTakeProfit === 0 ||
      symbol === '' ||
      direction === ''
    ) {
      return alert('Time, Price and Symbol fields are required');
    } else {
      dispatch(
        updateTrade({
          _id: tradeId,
          positionID,
          dateEntry,
          timeEntry,
          dateExit,
          timeExit,
          quantity,
          priceEntry,
          priceExit,
          symbol,
          direction,
          priceStopLoss,
          priceTakeProfit,
          dollarProfit,
          accountName,
          comment,
          tags,
          link,
          link1,
          link2,
          commissions,
          swap,
        })
      );
    }
  };

  return (
    <>
      <div className='my-4 mx-5'>
        <Row className='my-3 justify-content-center'>
          <h1 className='table-header'>Edit Trade</h1>
        </Row>
        <Row className='my-3 justify-content-center'>
          <Col className=''>
            <Link to='/journal' className='btn btn-primary'>
              Back to Journal
            </Link>
          </Col>
        </Row>
      </div>
      <Container>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <>
            <Form onSubmit={submmitHandler}>
              <Row className='my-3 py-2'>
                <Col>
                  <Card className='p-4 trade-input-card'>
                    <CardHeader className='text-center'>Trade Time</CardHeader>

                    <Row>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Entry Date</Form.Label>
                          <Form.Control
                            type='date'
                            name='dateEntry'
                            format='yyyy-MM-dd'
                            value={dateEntry}
                            onChange={(e) => setDateEntry(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <FormGroup className='my-3'>
                          <Form.Label>Entry Time</Form.Label>
                          <Form.Control
                            type='text'
                            name='timeEntry'
                            format='HH:mm:ss'
                            placeholder='hh:mm:ss'
                            value={timeEntry}
                            onChange={(e) => setTimeEntry(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Exit Date</Form.Label>
                          <Form.Control
                            type='date'
                            name='dateExit'
                            format='yyyy-MM-dd'
                            value={dateExit}
                            onChange={(e) => setDateExit(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <FormGroup className='my-3'>
                          <Form.Label>Exit Time</Form.Label>
                          <Form.Control
                            type='text'
                            name='timeExit'
                            format='HH:mm:ss'
                            placeholder='hh:mm:ss'
                            value={timeExit}
                            onChange={(e) => setTimeExit(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col>
                  <Card className='p-4 trade-input-card'>
                    <CardHeader className='text-center'>
                      Trade Information
                    </CardHeader>
                    <Row>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Symbol</Form.Label>
                          <Form.Control
                            type='text'
                            name='symbol'
                            value={symbol}
                            onChange={(e) => setSymbol(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control
                            type='number'
                            name='quantity'
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Direction</Form.Label>
                          <Form.Select
                            name='direction'
                            value={direction}
                            onChange={(e) => setDirection(e.target.value)}
                            required
                          >
                            <option></option>
                            <option value='Long'>Long</option>
                            <option value='Short'>Short</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Trade ID</Form.Label>
                          <Form.Control
                            type='number'
                            name='positionID'
                            value={positionID}
                            onChange={(e) => setPositionID(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Group className='my-3'>
                        <Form.Label>Account</Form.Label>
                        <Form.Control
                          type='text'
                          name='accountName'
                          value={accountName}
                          onChange={(e) => setAccountName(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row className='my-3 py-2'>
                <Col>
                  <Card className='p-4 trade-input-card'>
                    <CardHeader className='text-center'>
                      Price Information
                    </CardHeader>
                    <Row>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Entry Price</Form.Label>
                          <Form.Control
                            type='number'
                            name='entryPrice'
                            value={priceEntry}
                            onChange={(e) => setPriceEntry(e.target.value)}
                            required
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Exit Price</Form.Label>
                          <Form.Control
                            type='number'
                            name='exitPrice'
                            value={priceExit}
                            onChange={(e) => setPriceExit(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Price Take Profit</Form.Label>
                          <Form.Control
                            type='number'
                            name='takeProfit'
                            value={priceTakeProfit}
                            onChange={(e) => setPriceTakeProfit(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Price Stop Loss</Form.Label>
                          <Form.Control
                            type='number'
                            name='stopLoss'
                            value={priceStopLoss}
                            onChange={(e) => setPriceStopLoss(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Group className='my-3'>
                        <Form.Label>Profit/Loss in USD</Form.Label>
                        <Form.Control
                          type='number'
                          name='profitLoss'
                          value={dollarProfit}
                          onChange={(e) => setDollarProfit(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Commissions in USD</Form.Label>
                          <Form.Control
                            type='number'
                            name='commissions'
                            value={commissions}
                            onChange={(e) => setCommissions(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Swap in USD</Form.Label>
                          <Form.Control
                            type='number'
                            name='swap'
                            value={swap}
                            onChange={(e) => setSwap(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col>
                  <Card className='p-4 trade-input-card'>
                    <CardHeader className='text-center'>
                      Misc Information
                    </CardHeader>
                    <Row>
                      <Form.Group className='my-3'>
                        <Form.Label>Chart Link 1</Form.Label>
                        <Form.Control
                          type='text'
                          name='chartLink'
                          value={link}
                          onChange={(e) => setLink(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className='my-3'>
                        <Form.Label>Chart Link 2</Form.Label>
                        <Form.Control
                          type='text'
                          name='chartLink1'
                          value={link1}
                          onChange={(e) => setLink1(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className='my-3'>
                        <Form.Label>Chart Link 3</Form.Label>
                        <Form.Control
                          type='text'
                          name='chartLink2'
                          value={link2}
                          onChange={(e) => setLink2(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className='my-3'>
                        <Form.Label>Tags (seperate with comma (,))</Form.Label>
                        <Form.Control
                          type='text'
                          name='tags'
                          value={tags}
                          onChange={(e) => setTags(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row className='my-3 py-2'>
                <Col>
                  <Card className='p-4 trade-input-card'>
                    <CardHeader className='text-center'>Trade Notes</CardHeader>
                    <Row>
                      <Col>
                        <Form.Group className='my-3'>
                          <Form.Label>Notes</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows='10'
                            name='notes'
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Button variant='primary' type='submit'>
                Journal Trade
              </Button>
            </Form>
          </>
        )}
      </Container>
    </>
  );
};

export default TradeEditScreen;

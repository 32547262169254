import React from 'react';
import { Container, Table } from 'react-bootstrap';

const AccountHistoryTable = ({ tradeData }) => {
  return (
    <Container className='my-4'>
      <Table className='my-5' striped hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {tradeData.map((trade) => (
            <tr key={trade._id}>
              <td>{trade.dateEntry}</td>
              <td>
                {' '}
                {trade.investment >= 0
                  ? '$' + trade.investment.toLocaleString()
                  : '-$' + (trade.investment * -1).toLocaleString()}
              </td>
              <td>{trade.comment}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default AccountHistoryTable;

import {
  TRADE_LIST_SUCCESS,
  TRADE_LIST_REQUEST,
  TRADE_LIST_FAILURE,
  TRADE_LIST_RESET,
  TRADE_CREATE_REQUEST,
  TRADE_CREATE_SUCCESS,
  TRADE_CREATE_FAILURE,
  TRADE_CREATE_RESET,
  TRADE_DELETE_REQUEST,
  TRADE_DELETE_SUCCESS,
  TRADE_DELETE_FAILURE,
  TRADE_DETAILS_REQUEST,
  TRADE_DETAILS_SUCCESS,
  TRADE_DETAILS_FAILURE,
  TRADE_UPDATE_REQUEST,
  TRADE_UPDATE_SUCCESS,
  TRADE_UPDATE_FAILURE,
  TRADE_UPDATE_RESET,
} from '../constants/tradeConstants';

export const tradeListReducer = (state = { trades: [] }, action) => {
  switch (action.type) {
    case TRADE_LIST_REQUEST:
      return { loading: true, trades: [] };
    case TRADE_LIST_SUCCESS:
      return { loading: false, trades: action.payload };
    case TRADE_LIST_FAILURE:
      return { loading: false, error: action.payload };
    case TRADE_LIST_RESET:
      return { trades: [] };
    default:
      return state;
  }
};

export const tradeDetailsReducer = (
  state = { trade: { tags: [] } },
  action
) => {
  switch (action.type) {
    case TRADE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case TRADE_DETAILS_SUCCESS:
      return { loading: false, trade: action.payload };
    case TRADE_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const tradeUpdateReducer = (state = { trade: {} }, action) => {
  switch (action.type) {
    case TRADE_UPDATE_REQUEST:
      return { loading: true };
    case TRADE_UPDATE_SUCCESS:
      return { loading: false, success: true, trade: action.payload };
    case TRADE_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case TRADE_UPDATE_RESET:
      return { trade: {} };
    default:
      return state;
  }
};

export const tradeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRADE_CREATE_REQUEST:
      return { loading: true };
    case TRADE_CREATE_SUCCESS:
      return { loading: false, success: true, trade: action.payload };
    case TRADE_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case TRADE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const tradeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TRADE_DELETE_REQUEST:
      return { loading: true };
    case TRADE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TRADE_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
